import React from 'react';

import './Button.css';

export const Button = ({
  title,
  type = 'primary',
  onClick,
  disabled,
  style = {},
}) => {
  return (
    <button
      onClick={onClick}
      className={`button button-${type}`}
      disabled={disabled}
      style={style}
    >
      {title}
    </button>
  );
};
