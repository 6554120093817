import React, { useEffect, useState } from 'react';
import { Button } from '../Button/Button';
import { useRecoilValue } from 'recoil';
import { MenuOutlined } from '@ant-design/icons';
import './Nav.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import moment from 'moment';
import { user, userLocation } from '../../store/user';
import { UserDropdown } from '../UserDropdown/UserDropdown';
import { Auth } from '../Auth/Auth';
import { useIsMobile } from '../../hooks/IsMobile';
import dayjs from 'dayjs';
import { defaultGps } from '../../utils/constants';

export const Nav = () => {
  const [navStyle, setNavstyle] = useState('transparent');
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useRecoilValue(user);
  const [showmenu, setShowMenu] = useState(false);
  const isMobile = useIsMobile();
  const locationUser = useRecoilValue(userLocation);
  const defaultLocation = locationUser?.lat ? locationUser : defaultGps.gps;
  const defaultPlace = locationUser?.lat ? 'Near me' : defaultGps.name;

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setNavstyle('white');
      } else {
        setNavstyle('transparent');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (showmenu) {
      setShowMenu(false);
    }
  }, [location]);

  const formatedDate = dayjs().format('YYYY-MM-DD');

  return (
    <>
      <div className={['nav', navStyle].join(' ')}>
        <div>
          <img
            onClick={() => navigate('/')}
            style={{ width: '100px', cursor: 'pointer' }}
            src={require('../../assets/images/logo.png')}
          />
        </div>

        <div className='menu-toggle' onClick={() => setShowMenu(!showmenu)}>
          <MenuOutlined />
        </div>

        <div
          className='subs'
          style={{ display: isMobile ? (showmenu ? 'flex' : 'none') : 'flex' }}
        >
          <div className='nav-menu'>
            <Link style={{ textDecoration: 'none' }} to='/'>
              <div>Home</div>
            </Link>
            <Link
              style={{ textDecoration: 'none' }}
              to={`/hotels/${defaultLocation.lat}/${
                defaultLocation.lng
              }/${formatedDate}/${defaultPlace}/${
                locationUser?.cc || defaultGps.cc
              }`}
            >
              <div>Book a Room</div>
            </Link>
            <Link style={{ textDecoration: 'none' }} to='/faq'>
              <div>FAQ</div>
            </Link>
            <Link style={{ textDecoration: 'none' }} to='/contest'>
              <div>Contest</div>
            </Link>
          </div>

          <div className='user-login'>
            {userData ? (
              <UserDropdown user={userData} />
            ) : (
              <Button
                title='Login'
                type='secondary'
                onClick={() => handleOpenModal()}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        open={showModal}
        width={508}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Auth handleCloseModal={handleCloseModal} />
      </Modal>
    </>
  );
};
