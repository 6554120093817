import React, { useEffect } from 'react';
import { ShareAltOutlined } from '@ant-design/icons';
import './QR.css';

export const QR = () => {
  function shareContent() {
    if (navigator.share) {
      navigator
        .share({
          title: 'Share Nappr with others',
          url: 'https://www.nappr.io/apps',
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      // fallback for browsers that don't support the Web Share API
      console.log('Web Share API is not supported in your browser.');
    }
  }

  return (
    <div className="qr-page">
      <div className="qr-share">
        <ShareAltOutlined size={50} onClick={() => shareContent()} />
      </div>
      <div className="qr-logo">
        <div className="qr-logo-content">
          <img
            style={{ width: '120px', height: '120px' }}
            src={require('../../assets/images/footer/app-logo.png')}
          />
        </div>
      </div>
      <div className="qr-app-text">
        <h2>Nappr</h2>
        <p>
          Book 4 to 10-hour hotel stays for a fraction of the overnight cost.
        </p>
      </div>

      <div className="qr-apps">
        <a
          href="https://apps.apple.com/us/app/nappr/id1661113382?utm_source=apps_page"
          target="_blank"
        >
          <img
            src={require('../../assets/images/footer/apple-badge.png')}
            className="qr-apple"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.napprmobile&utm_source=apps_page&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
        >
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            height="100"
          />
        </a>
      </div>

      <div className="qr-link">
        <a href="https://www.nappr.io/" target="_blank">
          www.nappr.io
        </a>
      </div>
    </div>
  );
};
