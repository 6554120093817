import React, { useEffect, useState } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import axiosInstance from '../../utils/axios';
import { useSetRecoilState } from 'recoil';
import { user } from '../../store/user';

import './Social.css';

export const GoogleAuth = ({ handleCloseModal }) => {
  const [buttonWidth, setWidth] = useState(300);
  const setUser = useSetRecoilState(user);
  const handleGoogleSuccess = (response) => {
    // Send the response to the backend for further processing
    sendTokenToBackend(response.credential);
  };

  const handleGoogleFailure = (error) => {
    console.log('Google login failed:');
    // Handle error or perform any necessary actions
  };

  const sendTokenToBackend = (accessToken) => {
    axiosInstance
      .post('api/v1/auth/google', {
        code: accessToken,
      })
      .then((res) => {
        if (res?.data?.results) {
          localStorage.setItem('user', JSON.stringify(res.data.results));
          setUser(res?.data?.results);
          handleCloseModal();
        }
      });
  };

  useEffect(() => {
    const fbb = document.querySelector('.fbbutton');
    setWidth(fbb.clientWidth);
  }, []);

  return (
    <div style={{ marginTop: '30px' }}>
      <GoogleOAuthProvider clientId="772231057030-flg3va6slv0d0acmapb7n0bc20nrf57p.apps.googleusercontent.com">
        <GoogleLogin
          width={buttonWidth}
          text="continue_with"
          onSuccess={handleGoogleSuccess}
          onFailure={handleGoogleFailure}
          cookiePolicy={'single_host_origin'}
        />
      </GoogleOAuthProvider>
      {/* <button class='loginBtn loginBtn--facebook'>Login with Facebook</button>

      <button class='loginBtn loginBtn--google'>Login with Google</button> */}
      <div style={{ margin: '30px 0' }}>OR</div>
    </div>
  );
};
