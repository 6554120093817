import React, { useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { Search } from '../Landing/components/Search/Search';

import './Sitemap.css';

export const Sitemap = () => {
  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'Sitemap',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  return (
    <div>
      <div className='hotels-search'>
        <Search />
      </div>
      <div class='sitemap-container'>
        <h1>Site Map</h1>
        <br/>
        <div class="Links">
          <b>About Us</b>
            <p>
              <a href="https://www.nappr.io/team/" target="_blank">The Team</a><br/>
              <a href="https://www.nappr.io/faq/" target="_blank">FAQ</a><br/>
              <a href="https://www.nappr.io/contact-us/" target="_blank">Contact Us</a>
            </p>
          <br/>
          <b>Content</b>
            <p>
              <a href="https://www.nappr.io/blog/" target="_blank">Blog</a><br/>
              <a href="https://www.nappr.io/press/" target="_blank">Press</a><br/>
            </p>
            <br/>
          <b>Legal</b>
            <p>
              <a href="https://www.nappr.io/terms/" target="_blank">Terms of Service</a><br/>
              <a href="https://www.nappr.io/privacy/" target="_blank">Privacy Policy</a><br/>
              <a href="https://www.nappr.io/booking-policy/" target="_blank">Booking Policy</a><br/>
            </p>
        </div>
        <div class="Map">
          <a href="../../assets/images/world-map.png" target="_blank">   
            <img
                src={require('../../assets/images/world-map.png')}
                height='600'
                alt='Nappr Coverage Map'
            />
          </a>
        </div>
      </div>
    </div>
  );
};
