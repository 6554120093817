import React, { useState } from 'react';
import * as yup from 'yup';
import './Footer.css';
import { Link } from 'react-router-dom';
import { notification } from 'antd';
import axiosInstance from '../../utils/axios';

export const Footer = () => {
  const [subscribe, setSubscribe] = useState('');

  const subscribeNow = async () => {
    const emailSchema = yup.object().shape({
      email: yup
        .string()
        .email('Invalid email address')
        .required('Email is required and cannot be empty.'),
    });

    await emailSchema
      .validate({ email: subscribe })
      .then((v) => {
        axiosInstance.post('api/v1/subscribe', v).then((res) => {
          if (res?.data?.results) {
            notification.success({
              message:
                'You have been successfully subscribed to Nappr offers and newsletter.',
            });

            setSubscribe('');
          }
        });
      })
      .catch((e) => {
        notification.error({ message: e.errors[0] });
      });
  };

  return (
    <div className="footer">
      <div className="sections container">
        <div className="section">
          <img
            style={{ width: '100px' }}
            src={require('../../assets/images/footer/logo.png')}
          />
          <div className="social-section">
            <a
              className="social"
              href="https://www.facebook.com/NapprApp/"
              target="_blank"
            >
              <img src={require('../../assets/images/footer/facebook.png')} />
            </a>

            <a
              className="social"
              href="https://www.instagram.com/napprapp/"
              target="_blank"
            >
              <img src={require('../../assets/images/footer/instagram.png')} />
            </a>

            <a
              className="social"
              href="https://twitter.com/NapprApp"
              target="_blank"
            >
              <img src={require('../../assets/images/footer/twitter.png')} />
            </a>

            <a
              className="social"
              href="https://www.tiktok.com/@NapprApp"
              target="_blank"
              style={{ marginRight: '0px' }}
            >
              <img src={require('../../assets/images/footer/tiktok.png')} />
            </a>
          </div>
        </div>

        <div className="section section-menu">
          <div style={{ marginBottom: '35px' }} className="menu">
            MENU
          </div>
          <div className="menu">
            <Link to="/team">Team</Link>
          </div>
          <div className="menu">
            <Link to="/blog">Blog</Link>
          </div>
          <div className="menu">
            <Link to="/press">Press</Link>
          </div>
          <div className="menu">
            <Link to="/contact-us">Contact Us</Link>
          </div>
          {/*
          <div className='menu'>
            Press{" "}
            <span style={{ fontSize: "12px", color: "white" }}>
              (Coming soon)
            </span>
          </div>
*/}
        </div>
        <div className="section section-contact">
          {/*
          <div className='menu'>
            <Link to='/contact-us'>Contact Us</Link>
          </div>
          <div className='menu'>
            E-Mail:{" "}
            <Link
              to='javascript:void(0)'
              onClick={() => (window.location = "mailto:info@nappr.io")}
            >
              <span>info@nappr.io</span>
            </Link>
          </div>
*/}
        </div>

        <div className="section section-sub">
          <div style={{ marginBottom: '35px' }} className="menu">
            SUBSCRIBE
          </div>

          <div>
            <input
              value={subscribe}
              placeholder="Your email"
              onChange={(e) => setSubscribe(e.target.value)}
            />
            <img
              onClick={() => subscribeNow()}
              className="paper"
              src={require('../../assets/images/footer/telegram-plane.png')}
            />
          </div>
        </div>
      </div>

      <div className="hr" />

      <div className="footer-bottom container">
        <div className="footer-bottom-payment">
          <img src={require('../../assets/images/footer/cc-visa.png')} />
          <img src={require('../../assets/images/footer/cc-mastercard.png')} />
          <img src={require('../../assets/images/footer/cc-apple-pay.png')} />
        </div>

        <div id="bottomLinks" className="section">
          <Link style={{ textDecoration: 'none' }} to={'/terms'}>
            <div className="menu">Terms</div>
          </Link>
          <Link style={{ textDecoration: 'none' }} to={'/privacy'}>
            <div className="menu">Privacy</div>
          </Link>
          <Link style={{ textDecoration: 'none' }} to={'/booking-policy'}>
            <div className="menu">Booking Policy</div>
          </Link>
          <Link style={{ textDecoration: 'none' }} to={'/sitemap'}>
            <div className="menu">Site Map</div>
          </Link>
        </div>

        <div
          style={{ color: 'rgba(255, 255, 255, 0.502972)' }}
          className="menu"
        >
          <div id="Copyright">© 2024 Forty Winks LLC</div>
        </div>
      </div>
    </div>
  );
};
