import React from "react";
import './404Page.css';
import { Search } from '../Pages/Landing/components/Search/Search';

export const PageNotFound = () => {
    return (
        <div className="errorPage">
            <div className='hotels-search'>
                <Search />
            </div>
            <div className="errorPage-content">
                <p>There are at LEAST<span className="error404">404</span>reasons why you should take a nap today.</p>
                <br/>
                <p>This page isn't one of them sadly.</p>
            </div>
        </div>
    );
};