import React, { useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { Search } from '../Landing/components/Search/Search';

import './Team.css';

export const Team = () => {
  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'Team',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  return (
    <div>
      <div className='hotels-search'>
        <Search />
      </div>
      <div class='team-container'>
        <h1>Team Nappr</h1>
        <div>
          <div class='row'>
            <div>
              <br />
              <div>
                <img
                  src={require('../../assets/images/team/jared-lerner.jpg')}
                  height='200'
                  width='200'
                  alt='Jared Lerner'
                />
              </div>
              <div>
                <b>Jared Lerner</b>
                <br />
                <span>Founder and ZzZEO</span>
              </div>
              <div>
                <p>
                  Jared brings over 17 years of Software Engineering experience
                  at various New York City tech startups, where he's done
                  everything from building Support departments from scratch all
                  the way to running the company olympics. His idea for Nappr
                  stemmed from his own personal battle with sleep over the
                  years, which often led to a lot of tired days and consuming
                  tons of caffeine as a workaround. After searching for
                  healthier alternatives, he came across several studies that
                  showed how beneficial napping could be and he instantly became
                  hooked. It made him determined to make this practice
                  affordable and accessible to anybody who needed to do so, and
                  it is what drives him and Nappr to this day.
                </p>
                <p>
                  He holds a Bachelors of Computer Science from Washington
                  University in St. Louis and an MBA from NYU Stern, where he
                  focused on Entrepreneurship, Innovation and Design. When he's
                  not being a sleep evangelist, you can find him drumming with
                  his band West of Eve, playing Softball, cheering on the
                  Yankees, Giants, Knicks and Devils and spoiling his cat Bianca
                  to no end.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class='row'>
            <div>
              <br />
              <div>
                <img
                  src={require('../../assets/images/team/daniel-corona.png')}
                  height='200'
                  width='200'
                  alt='Daniel Corona'
                />
              </div>
              <div>
                <b>Daniel Corona</b>
                <br />
                <span>Chief Marketing Officer</span>
              </div>
              <div>
                <p>
                  Chief Sales and Marketing Officer Daniel brings nearly 15+
                  years of combined Marketing, Client Service, Recruiting and
                  Accounting experience from both Corona & Associates and Ernst
                  & Young. Daniel has always had a networking and
                  entrepreneurship mindset and leveraged his Global Marketing
                  and Sales connections to create "The NFT Pixel Project", which
                  brought together over 1,000 Artists from more than 40+
                  countries on one canvas for Art Basel Miami. He is both a
                  Superhost for AirBnb and an Ambassador Elite Member for
                  Marriott and he loves the creative idea of implementing the
                  same strategy, but for short-term, hourly naps! Starting each
                  day around 5am, a mid-day "Siesta" is exactly what he needs to
                  be the best version of himself. Daniel is extremely passionate
                  about Sales, Marketing and closing the "Nap-Gap", which is how
                  he became the Chief Sales and Marketing Officer at Nappr.
                  Daniel received his Bachelors in Business Management Economics
                  from the University of California Santa Cruz and earned a
                  Masters of Science in Accountancy from the University of Notre
                  Dame. When he's not explaining the amazing health benefits and
                  positive results of Naps, you can find him traveling the World
                  (He's been to 71+ countries) and attending major sporting
                  events (World Series, Super Bowl, NBA Finals, Olympics etc.).
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class='row'>
            <div>
              <br />
              <div>
                <img
                  src={require('../../assets/images/team/ryan-king.png')}
                  height='200'
                  width='200'
                  alt='Ryan King'
                />
              </div>
              <div>
                <b>Ryan King</b>
                <br />
                <span>Director of Marketing</span>
              </div>
              <div>
                <p>
                  Ryan is adept at helping companies scale their brand and online presence, and he 
                  is the driving force behind many of our social media efforts. His background is in 
                  influencer marketing and business development with a focus on customer acquisition, 
                  and he prides himself on adding value and creating scalable processes.
                </p>
                <p>
                  In his 7+ year career he's led hundreds of campaigns for companies like FanDuel, 
                  Sam's Club and IMF and he's worked with dozens of large brands in the process.
                  He holds a Bachelors in Marketing from Cal State Dominguez Hills.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class='row'>
            <div>
              <br />
              <div>
                <img
                  src={require('../../assets/images/team/hamza-iqbal.png')}
                  height='200'
                  width='200'
                  alt='Hamza Iqbal'
                />
              </div>
              <div>
                <b>Hamza Iqbal</b>
                <br />
                <span>Product Designer</span>
              </div>
              <div>
                <p>
                Hamza has spent eight years creating engaging stories through visuals and words for both startups and big companies in the e-commerce and hospitality industries, including some well-known names like W Hotels, British Airways, and PayPal. He's a passionate traveler and sleep enthusiast, and has enjoyed taking naps in 55 different countries so far.                </p>
                <p>
                He holds a BSc in International Politics degree from Georgetown University, and a Masters in Communication Design from Parsons School of Design. 
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class='row'>
            <div>
              <br />
              <div>
                <img
                  src={require('../../assets/images/team/rajan-meswani.png')}
                  height='200'
                  width='200'
                  alt='Rajan Meswani'
                />
              </div>
              <div>
                <b>Rajan Meswani</b>
                <br />
                <span>Business Development Strategist</span>
              </div>
              <div>
                <p>
                  Rajan is a Business Development and GTM Strategist who evaluated dozens of SaaS startups 
                  as a Venture Capitalist for EduLab Capital Partners, where he focused on deal sourcing, company diligence, and 
                  financial modeling. Prior to EduLab, he  was a Research Analyst at Clearbrook Global Advisors - an Outsourced Chief Investment Office 
                  and Investment Advisory firm. He has conducted multi-asset investment due diligence for dozens of hedge funds and private equity funds, 
                  including thorough macroeconomic analysis and portfolio management.
                </p>
                <p>
                  In his spare time he is a passionate mentor for many aspiring entrepreneurs who are looking to take their ventures to the next level. He 
                  holds a B.S in Finance from Bentley University and an MBA from Boston College.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div style={{marginTop:'70px'}}>
        <h1>Advisory Board</h1>
          <div class='row'>
            <div>
              <br />
              <div>
                <img
                  src={require('../../assets/images/team/aaron-chavez.png')}
                  height='200'
                  width='200'
                  alt='Aaron Chavez'
                />
              </div>
              <div>
                <b>Aaron Chavez</b>
                <br />
                <span>Advisor</span>
              </div>
              <div>
                <p>
                  Aaron comes from a Wall Street background and served as an
                  analyst and associate at Barclays Investment Bank in
                  Manhattan. His work centered around equity capital markets and
                  investor engagement for businesses across multiple industries,
                  where he supported the raise of billions of dollars of capital
                  over his tenure. His passion for advising led him to his
                  current role, where he now focuses on supporting Startups,
                  Web3 Businesses, and Small Businesses across their business,
                  financial, and capital raising needs.{' '}
                </p>
                <p></p>
                <p>
                  He holds a degree in Finance from the W. P. Carey School of
                  Business &amp; Barrett, The Honors College at Arizona State
                  University.{' '}
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class='row'>
            <div>
              <br />
              <div>
                <img
                  src={require('../../assets/images/team/cliff-locks.png')}
                  height='200'
                  width='200'
                  alt='Cliff Locks'
                />
              </div>
              <div>
                <b>Clifford Locks</b>
                <br />
                <span>Sr. Advisor</span>
              </div>
              <div>
                <p>
                  Cliff has built a reputation as a mentor, coach and team
                  facilitator to Boards, directors and senior executives at some
                  of the country's leading private and public sector
                  organizations. He has held both executive and non-executive
                  directorship positions in the supply chain, consumer products,
                  automotive, exhibit, ad agency, sales promotion, medical and
                  technology sectors. Over the course of his career, Cliff has
                  specialized in team building, improving reverse logistics and
                  supply chain management, and he has consulted with executives
                  at Amazon, Assurant Solutions, Best Buy, QVC, Black & Decker,
                  Walmart, LG and other household names.{' '}
                </p>
                <p>
                  He has founded and executed the sale of three successful
                  companies including International Automotive Showcase, Inc.,
                  an Auto Show Production Company headquartered in New York. The
                  firm produced five annual, international new car shows in the
                  Northeast market for seventeen years and attracted 1.1M
                  customers in a four-state area.{' '}
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
