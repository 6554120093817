import React from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import axiosInstance from '../../utils/axios';
import { useSetRecoilState } from 'recoil';
import { user } from '../../store/user';

const appId = '380457629699012';

export const FacebookAuth = ({ handleCloseModal }) => {
  const setUser = useSetRecoilState(user);
  const sendTokenToBackend = (accessToken) => {
    axiosInstance
      .post('api/v1/auth/facebook', {
        code: accessToken,
      })
      .then((res) => {
        if (res?.data?.results) {
          localStorage.setItem('user', JSON.stringify(res.data.results));
          setUser(res?.data?.results);
          handleCloseModal();
        }
      });
  };

  return (
    <FacebookLogin
      style={{
        backgroundColor: '#4267b2',
        color: '#fff',
        fontSize: '16px',
        padding: '12px 24px',
        border: 'none',
        borderRadius: '4px',
        width: '100%',
      }}
      className="fbbutton"
      appId={appId}
      onSuccess={(response) => {
        sendTokenToBackend(response.accessToken);
      }}
    />
  );
};
