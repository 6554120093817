import React from 'react';
import { Tabs } from 'antd';
import LoginForm from '../Login/Login';
import { RegisterForm } from '../Register/Register';
import ForgotPassword from '../ForgotPassword/ForgotPassword';

export const Auth = ({ handleCloseModal }) => {
  const items = [
    {
      key: '1',
      label: `Signin`,
      children: <LoginForm handleCloseModal={handleCloseModal} />,
    },
    {
      key: '2',
      label: `Signup`,
      children: <RegisterForm handleCloseModal={handleCloseModal} />,
    },
    {
      key: '3',
      label: `Forgot password`,
      children: <ForgotPassword handleCloseModal={handleCloseModal} />,
    },
  ];

  return <Tabs defaultActiveKey='1' items={items} />;
};
