import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';

import './Hotels.css';
import axiosInstance from '../../../../utils/axios';
import { CustomImage } from '../../../../Components/Image/Image';
import dayjs from 'dayjs';
import { currenices } from '../../../../utils/currency';
import { useRecoilValue } from 'recoil';
import { userLocation } from '../../../../store/user';
import { defaultGps } from '../../../../utils/constants';
import { haversineDistance } from '../../../../utils/helpers';
import TiktokPixel from 'tiktok-pixel';

export const Hotels = ({ activeId, date }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useRecoilValue(userLocation);
  const realLocation = location?.cc
    ? location
    : { ...defaultGps, lat: defaultGps.gps.lat, lng: defaultGps.gps.lng };

  useEffect(() => {
    if (location) {
      const searchGps = params.lat
        ? {
            lat: parseFloat(params.lat),
            lng: parseFloat(params.lng),
          }
        : location.code
        ? defaultGps.gps
        : location;

      axiosInstance
        .get(
          `api/v1/hotels/all/${date || dayjs().format('YYYY-MM-DD')}/1/${
            searchGps.lat
          }/${searchGps.lng}/${params.cc || location.cc || defaultGps.cc}`
        )
        .then((res) => {
          if (res?.data?.results) {
            setHotels(res.data.results.hotels);
            setLoading(false);
          }
        });
    }
  }, [location]);

  if (loading || !location) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      {!hotels.length ||
      (hotels.length === 1 && hotels[0].hotel_id === activeId) ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <h3>There are no other hotels in this area.</h3>
        </div>
      ) : (
        hotels
          .filter((h) => h.least_expensive_product?.total_price)
          .filter((h) => h.hotel_id !== activeId)
          .sort((a, b) => {
            if (!a.distance) {
              a.distance = haversineDistance(
                a.gps.lat,
                a.gps.lon,
                realLocation.lat,
                realLocation.lng
              );
            }
            if (!b.distance) {
              b.distance = haversineDistance(
                b.gps.lat,
                b.gps.lon,
                realLocation.lat,
                realLocation.lng
              );
            }

            return a.distance - b.distance;
          })
          .slice(0, 4)
          .map((hotel) => {
            if (!hotel.distance) {
              hotel.distance = haversineDistance(
                hotel.gps.lat,
                hotel.gps.lon,
                realLocation.lat,
                realLocation.lng
              );
            }
            var minRate = hotel.least_expensive_product?.total_price;
            var maxRate = hotel.most_expensive_product?.total_price;

            return (
              <div
                key={hotel.hotel_id}
                className="hotel"
                onClick={() => {
                  TiktokPixel.track('ClickButton', {
                    content_name: 'Hotel',
                    content_type: 'product',
                    timestamp: new Date(),
                    description: 'Clicked on a hotel from landing page',
                    query: hotel.name,
                  });

                  navigate(
                    `/hotel/${hotel.hotel_id}/${
                      params?.date || dayjs().format('YYYY-MM-DD')
                    }/${realLocation.lat}/${realLocation.lng}/${
                      realLocation?.name || 'Near me'
                    }/${realLocation.cc}`,
                    { replace: true }
                  );
                }}
              >
                <div className="hotel-distance">{hotel.distance}mi away</div>
                <div className="hotel-image">
                  <CustomImage url={hotel.images?.[0]?.url} />
                </div>

                <div className="hotel-info">
                  <div className="hotel-name">{hotel.name}</div>
                  <div className="hotel-time">
                    {`${hotel.address} `}
                    <div
                      style={{ lineHeight: 0, marginBottom: '15px' }}
                    >{`${hotel.city}, ${hotel.state}`}</div>
                  </div>

                  <b>
                    {minRate === maxRate
                      ? `${
                          currenices[hotel.most_expensive_product?.currency]
                            .symbol
                        } ${minRate}`
                      : `${
                          currenices[hotel.most_expensive_product?.currency]
                            .symbol
                        } ${minRate} - ${
                          currenices[hotel.most_expensive_product?.currency]
                            .symbol
                        } ${maxRate}`}
                  </b>
                </div>
              </div>
            );
          })
      )}
    </>
  );
};
