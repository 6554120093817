import React from 'react';

import './Steps.css';

export const Steps = ({ steps }) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <div className='steps'>
        <div className={`circle ${steps === 0 ? 'progress' : 'done'}`} />
        <div className={`hr ${steps >= 2 ? 'done' : undefined}`} />
        <div
          className={`circle ${
            steps === 1 ? 'progress' : steps >= 2 ? 'done' : undefined
          }`}
        />
      </div>
      <div
        style={{
          width: '70px',
          height: '5px',
          background: '#2194ff',
          borderRadius: '5px',
          marginTop: '20px',
        }}
      />
    </div>
  );
};
