import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  MailOutlined,
  LockFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';

import { Button, Input } from 'antd';
import * as Yup from 'yup';
import axiosInstance from '../../utils/axios';
import { user } from '../../store/user';
import { GoogleAuth } from '../SocialLogin/GoogleAuth';
import { FacebookAuth } from '../SocialLogin/FacebookAuth';

const LoginForm = ({ handleCloseModal }) => {
  const setUser = useSetRecoilState(user);
  const [buttonLoading, setbuttonLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: (values) => {
      setbuttonLoading(true);
      // Handle form submission
      axiosInstance.post('api/v1/auth/login', values).then((res) => {
        if (res?.data?.results) {
          localStorage.setItem('user', JSON.stringify(res.data.results));
          setUser(res.data.results);

          handleCloseModal();
        }
        setbuttonLoading(false);
      });
    },
  });

  return (
    <div style={{ padding: '0 30px' }}>
      <FacebookAuth handleCloseModal={handleCloseModal} />
      <GoogleAuth handleCloseModal={handleCloseModal} />

      <form onSubmit={formik.handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <Input
            size="large"
            prefix={<MailOutlined />}
            type="email"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.email && formik.errors.email && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.email}
            </div>
          )}
        </div>
        <br />
        <div>
          <label htmlFor="password">Password:</label>
          <Input.Password
            size="large"
            prefix={<LockFilled />}
            type="password"
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          {formik.touched.password && formik.errors.password && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.password}
            </div>
          )}
        </div>
        <br />
        <Button type="primary" loading={buttonLoading} htmlType="submit">
          Login
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
