import React, { useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { Link } from 'react-router-dom';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, theme } from 'antd';

import './Contest.css';
import { Search } from '../Landing/components/Search/Search';

export const Contest = () => {
  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'Contest',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  return (
    <div className='contest-page'>
      <div className='hotels-search'>
        <Search />
      </div>
      <div id='logo' className='logoDiv'>
          <img 
            className='contestLogo'
            src={require('../../assets/images/contest/contest-logo-train.png')}
            height='450'
          />
        </div>
      <div className='contest-page-rules'>
        <h1>Our Mobile Bed contest is now open!</h1>
        <h2>To enter, follow the instructions below:</h2>
        <h3>
          1. Starting on Thursday, September 5th, capture a photo or video of someone napping in our mobile Nappr bed (if you missed the moment, no worries! You can still reshare a post from our @NapprApp Instagram or TikTok account instead). 
          <br/><br/>
          2. Add a captivating caption that perfectly describes the moment and tag our <b>@NapprApp</b> handle in an Instagram story or TikTok. The most creative captions will win fantastic prizes below! It's that simple!
          <br/><br/>
          3. Bonus Step! If you'd like to nap in a specific hotel room on our platform, <a href="https://qrco.de/contest_app_link" target="_blank">download the Nappr app</a> on iOS or Android, screenshot your favorite hotel and tag us with it in a separate Instagram story. You could win a stay in the very room you've chosen!
        </h3>
      </div>
      <div className='contest-page-prizes'>
        <h2>Contest Prizes</h2>
        <h3>
            <br/><b>Grand Prize</b><br/>A "Nap and Explore” package that includes:<br/>
              <ul>
                <li>A free midday nap session at one of our Nappr locations</li>
                <li>A pass to a local attraction (e.g., a museum, botanical garden, or historical site) for post-nap exploration, AND</li>
                <li>A gourmet picnic basket to enjoy after your nap and tour</li>
              </ul><br/>
              <b>2nd Place</b><br/>A high-quality weighted blanket, a set of premium pillows, and a sleep mask.<br/><br/><br/>
              <b>3rd Place</b><br/>A create-your-own sleep kit with items like essential oils, herbal teas, and cozy socks<br/><br/><br/>
              <b>4th Place</b><br/>A few hours' stay in one of our hotels!<br/><br/><br/>
              <b>5th Place</b><br/>A super cool white noise machine<br/><br/><br/>
            And last but not least...<br/><br/><br/>
            <b>People's Choice Award!</b><br/>A separate prize for the post with the most likes or comments!<br/><br/>
        </h3><br/>
        <h3 style={{ marginTop:'0px'}}>
          <b>Submission Deadline: September 20th at 11:59 p.m. PST</b>
        </h3>
          <b>All winners will be announced on September 21st <a href ="https://www.instagram.com/napprapp" target="_blank"> our Instagram page</a> so make sure to follow us for the results!</b>
      </div>
    </div>
  );
}