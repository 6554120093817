import React, { useState } from 'react';

import './Hotel.css';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomImage } from '../../../../Components/Image/Image';
import TiktokPixel from 'tiktok-pixel';

export const Hotel = ({
  image,
  name,
  address,
  cityAndState,
  id,
  startDate,
  map,
  time,
  price,
  distance,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div
      className="hotel-infos"
      onClick={() => {
        TiktokPixel.track('ClickButton', {
          content_name: 'Hotel',
          content_type: 'product',
          timestamp: new Date(),
          description: 'Clicked on a hotel from hotels page',
          query: name,
        });

        navigate(
          `/hotel/${id}/${startDate}/${params.lat}/${params.lng}/${params.geoname}/${params.cc}`
        );
      }}
    >
      <div className="hotel-distance">{distance}mi away</div>
      <CustomImage url={image} />
      <div className={`hotel-info ${map ? 'hotel-info-map' : undefined}`}>
        <div>
          <h2>{name}</h2>
          <div>
            <p style={{ lineHeight: map ? 'inherit' : 0 }}>{address}</p>
            <p>{cityAndState}</p>
          </div>
        </div>
        <div>
          <p style={{ fontSize: '13px'}}>{time}</p>
          <b style={{ fontWeight: 'bold' }}>{price}</b>
        </div>
      </div>
    </div>
  );
};
