import React, { useEffect } from 'react';

import './ConfirmEmail.css';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axios';

export const ConfirmEmail = () => {
  const params = useParams();
  useEffect(() => {
    axiosInstance.post('api/v1/auth/confirm-email', { token: params.token });
  }, []);

  return (
    <div className='confirmation-email'>
      <div className='confirmation-email-title'>Email Confirmed!</div>
      <br />
      <p>
        Congratulations! Your email has been confirmed and your account is now ready for use.
      </p>
      <div>
        <br />
        <div>Enjoy all of your extra ZzZs!</div>
      </div>
    </div>
  );
};
