import React from 'react';
import './Booking.css';
import { useNavigate } from 'react-router-dom';
import { currenices } from '../../../../utils/currency';
import dayjs from 'dayjs';

export const Booking = ({ booking, past }) => {
  const navigate = useNavigate();

  const image = booking?.hotel?.images.length
    ? booking?.hotel?.images[0].url
    : 'https://landgasthof-hotel-gentner.de/wp-content/uploads/resized/2023/03/6982-0x600-c-default.jpg';

  return (
    <div
      onClick={() => navigate(`/hotel/${booking.hotel.hotel_id}`)}
      style={{ opacity: past ? '0.5' : '1' }}
      className='booking-n'
    >
      <div className='booking-n-image'>
        <img alt='Nappr' src={image} />
      </div>
      <div className='booking-n-info'>
        <div className='booking-n-info-part'>
          <h3>{booking.hotel.name}</h3>
          <p style={{ marginBottom: 0 }}>{`${booking.hotel.address} `}</p>
          <div>{`${booking.hotel.city}, ${booking.hotel.state}`}</div>
          <p>Booking Number: #{`${booking.booking_number}`}</p>
        </div>

        <div className='booking-n-info-part'>
          <h3>{`${currenices[booking.currency].symbol}${
            booking.total_price
          }`}</h3>
          <p>{dayjs(booking.date).format('MM-DD-YYYY')}</p>
          {booking.canceled && <p style={{ color: 'red' }}>Canceled</p>}
        </div>
      </div>
    </div>
  );
};
