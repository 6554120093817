import React from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ user, children }) => {
  const localUser = localStorage.getItem('user');

  if (!localUser) {
    return <Navigate to='/' replace />;
  }

  return children;
};
