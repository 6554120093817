import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  MailOutlined,
  LockFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserAddOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Input, Button } from 'antd';
import * as Yup from 'yup';
import axiosInstance from '../../utils/axios';
import { user } from '../../store/user';
import { useSetRecoilState } from 'recoil';
import { GoogleAuth } from '../SocialLogin/GoogleAuth';
import { FacebookAuth } from '../SocialLogin/FacebookAuth';

export const RegisterForm = ({ handleCloseModal }) => {
  const setUser = useSetRecoilState(user);
  const [buttonLoading, setbuttonLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      phone: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      password: Yup.string().required('Password is required'),
      firstname: Yup.string().required('First name is required'),
      lastname: Yup.string().required('Last name is required'),
      phone: Yup.string().required('Phone number is required'),
    }),
    onSubmit: (values) => {
      setbuttonLoading(true);
      // Handle form submission
      axiosInstance.post('api/v1/auth/register', values).then((res) => {
        if (res?.data?.results) {
          localStorage.setItem('user', JSON.stringify(res.data.results));
          setUser(res.data.results);
          handleCloseModal();
        }

        setbuttonLoading(false);
      });
    },
  });

  return (
    <div style={{ padding: '0 30px' }}>
      <FacebookAuth handleCloseModal={handleCloseModal} />
      <GoogleAuth handleCloseModal={handleCloseModal} />
      <form onSubmit={formik.handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <Input
            size="large"
            prefix={<MailOutlined />}
            type="email"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.email && formik.errors.email && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.email}
            </div>
          )}
        </div>
        <br />
        <div>
          <label htmlFor="password">Password:</label>
          <Input.Password
            size="large"
            prefix={<LockFilled />}
            type="password"
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          {formik.touched.password && formik.errors.password && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.password}
            </div>
          )}
        </div>
        <br />
        <div>
          <label htmlFor="firstname">First name:</label>
          <Input
            size="large"
            prefix={<UserAddOutlined />}
            type="firstname"
            id="firstname"
            name="firstname"
            value={formik.values.firstname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.firstname && formik.errors.firstname && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.firstname}
            </div>
          )}
        </div>
        <br />
        <div>
          <label htmlFor="lastname">Last name:</label>
          <Input
            size="large"
            prefix={<UserAddOutlined />}
            type="lastname"
            id="lastname"
            name="lastname"
            value={formik.values.lastname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.lastname && formik.errors.lastname && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.lastname}
            </div>
          )}
        </div>
        <br />
        <div>
          <label htmlFor="phone">Phone:</label>
          <Input
            size="large"
            prefix={<PhoneOutlined />}
            type="phone"
            id="phone"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.phone && formik.errors.phone && (
            <div style={{ fontSize: '15px', color: 'red' }}>
              {formik.errors.phone}
            </div>
          )}
        </div>
        <br />
        <Button type="primary" loading={buttonLoading} htmlType="submit">
          Register
        </Button>
      </form>
    </div>
  );
};
