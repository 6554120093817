import { useEffect } from 'react';

import axiosInstance from '../../utils/axios';
import { useSetRecoilState } from 'recoil';
import { user } from '../../store/user';
import { notification } from 'antd';

export const DeletePage = () => {
  const setUserData = useSetRecoilState(user);

  useEffect(() => {
    setTimeout(() => {
      if (window.confirm('Are you sure you want to delete your account?')) {
        // Save it!
        axiosInstance.post('api/v1/auth/delete-account').then((res) => {
          if (res?.data?.results) {
            setUserData(null);
            localStorage.removeItem('user');
            notification.success({
              message: 'Your account was successfully deleted.',
              description:
                'We are sad to see you go. Feel free to come back and book with us again!',
            });
          }
        });
      } else {
        // Do nothing!
        console.log('Thing was not saved to the database.');
      }
    }, 300);
  });

  return <div style={{ height: '100%' }}></div>;
};
