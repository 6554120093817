import React, { useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { Search } from '../Landing/components/Search/Search';

import { ProfileData } from './components/ProfileData';
import { ProfilePassword } from './components/ProfilePassowrd';
import { Button } from 'antd';

export const Profile = () => {
  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'Profile',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  return (
    <div>
      <div className="hotels-search">
        <Search />
      </div>

      <div
        style={{
          padding: '50px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '45%' }}>
          <ProfileData />
        </div>

        <div style={{ width: '45%' }}>
          <ProfilePassword />
        </div>
      </div>
    </div>
  );
};
