import React from 'react';

import './Header.css';
import { Search } from '../Search/Search';

export const Header = () => {
  return (
    <div className='landing-header'>
      <div className='home-header-content'>
        <h1>Relax, on your schedule</h1>
        <div id='TitleSubtext'>
          <h3>
             Choose from thousands of flexible
          </h3>
          <h3>
            <strong>daily hotel rates</strong>
          </h3>
        </div>
      </div>

      <div id='hotelSearch' style={{ display: 'flex', justifyContent: 'center' }}>
        <Search />
      </div>
    </div>
  );
};
